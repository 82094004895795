import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { transformersForecastsSelector, transformersForecastsCountSelector } from 'modules/networkLoading/selectors';
import { fetchTransformersForecastAction } from 'modules/networkLoading';
import { setLayoutAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { PaginationType, ProfileModalTypes } from 'constants/index';
import { IconDetails } from '@utiligize/shared/resources';

const TableTransformersForecast: React.FC = () => {
  const { dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const paginationType = PaginationType.NETWORK_LOADING_TRANSFORMERS_FORECAST;

  const items = useSelector(transformersForecastsSelector);
  const count = useSelector(transformersForecastsCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchTransformersForecastAction(params)).then(
        (action: Shared.ReduxAction<Type.Hash<Pick<NetworkLoading.Root, 'transformersForecastsHash'>>>) =>
          Object.values(action.payload?.transformersForecastsHash || {})
      ),
    [dispatch]
  );

  const handleSeeProfileBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const assetName: string | null = event.currentTarget.getAttribute('data-id');
      if (!assetName) return;
      dispatch(setLayoutAction({ profileModalMeta: { id: assetName, type: ProfileModalTypes.TransformerForecast } }));
    },
    [dispatch]
  );

  return (
    <DataTable paginationType={paginationType} totalAmount={count} sendRequest={sendRequest} isDownloadCSVEnabled>
      {items?.map((item: NetworkLoading.TransformerForecast) => (
        <tr key={item.id}>
          <td>{item.transformer}</td>
          <td>{item.dateDeployed ? dateFormat(item.dateDeployed) : '-'}</td>
          <td>{item.ratingKva}</td>
          <td>{item.max_24h_pc || '-'}</td>
          <td>{item.max_24h_pu || '-'}</td>
          <td>{item.max_48h_pc || '-'}</td>
          <td>{item.max_48h_pu || '-'}</td>
          <td>{item.max_7d_pc || '-'}</td>
          <td>{item.max_7d_pu || '-'}</td>
          <td>{item.overload_start ? dateFormat(item.overload_start) : '-'}</td>
          <td>{item.peak ? dateFormat(item.peak) : '-'}</td>
          <td className="text-right">
            <Button
              tooltipKey="Profile"
              icon={<IconDetails />}
              data-id={item.transformer}
              onClick={handleSeeProfileBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableTransformersForecast;
