import styled from 'styled-components';
import React, { useState, useMemo, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { portfolioOptionsSelector, scenarioOptionsHashSelector } from 'modules/options/selectors';
import { DataTableTemplate, ContentContainer, ContentCard, Button } from 'components/_common';
import SelectChartType, { ChartTypeKeys, ChartTypes } from './SelectChartType';
import SelectAnalysisToolPortfolio from './SelectAnalysisToolPortfolio';
import SelectAnalysisToolScenario from './SelectAnalysisToolScenario';
import ChartAnalysis from './ChartAnalysis';
import { IconDelete, IconPlus } from '@utiligize/shared/resources';
import ChartInvestmentSummary from './ChartInvestmentSummary';

const ViewAnalysisTool: React.FC = () => {
  const [typeOption, setTypeOption] = useState(ChartTypes.TR_LOADING);
  const [loading, setLoading] = useState<boolean>(false);

  const portfolioOptions = useSelector(portfolioOptionsSelector);
  const scenarioOptionsHash = useSelector(scenarioOptionsHashSelector);

  const getInitPortfolioScenarioState = useCallback(() => {
    return portfolioOptions?.reduce((acc: any, { value }) => {
      const scenarioOptions = scenarioOptionsHash?.[value];
      if (!scenarioOptions || acc.length >= 2) return acc;
      scenarioOptions.forEach(option => {
        if (option.value && acc.length < 2) acc.push([value, option.value]);
      });
      return acc;
    }, []);
  }, [portfolioOptions, scenarioOptionsHash]);

  const [ids, setIds] = useState<number[][]>(getInitPortfolioScenarioState());

  const portfolioScenarioHash: { [key: string]: number[] | null } = useMemo(() => {
    return portfolioOptions?.reduce((acc: any, item) => {
      const scenarioIds = ids.map(([_, scenarioId]) => scenarioId);
      const scenarioOptions = scenarioOptionsHash?.[item.value] || [];
      const values = scenarioOptions
        .filter(scenario => !scenarioIds.includes(scenario.value))
        .map(scenario => scenario.value);
      // Note. Remove portfolio id hash key when all scenarios already in use
      if (scenarioOptions.length && !values.length) return acc;
      // Note. Return null in case if scenarios must be fetched
      acc[item.value] = scenarioOptions.length ? values : null;
      return acc;
    }, {});
  }, [portfolioOptions, scenarioOptionsHash, ids]);

  const handleAddSetClick = useCallback(() => {
    const portfolioId = Number(Object.keys(portfolioScenarioHash)[0]);
    const scenarioId = portfolioScenarioHash[portfolioId]?.[0];
    if (!portfolioId || !scenarioId) return;
    setIds(prev => [...prev, [portfolioId, scenarioId]]);
  }, [portfolioScenarioHash]);

  const handleDeleteBtnClick = useCallback((event: React.SyntheticEvent) => {
    const index: number = Number(event.currentTarget.getAttribute('data-index'));
    setIds(prev => prev.filter((_, i) => i !== index));
  }, []);

  return (
    <DataTableTemplate paddingBottom>
      <>
        <Row className="mb-3">
          {ids.map(([portfolioId, scenarioId], index: number) => (
            <Col key={portfolioId + scenarioId} xs={2}>
              <StyledCard>
                <SelectAnalysisToolPortfolio
                  value={portfolioId}
                  options={
                    portfolioOptions
                      ?.filter(
                        option =>
                          option.value === portfolioId ||
                          Object.keys(portfolioScenarioHash).includes(String(option.value))
                      )
                      .map(option => ({
                        ...option,
                        scenarioIds: portfolioScenarioHash[option.value] || [],
                      })) || []
                  }
                  index={index}
                  setIds={setIds}
                  isDisabled={loading}
                />
                <SelectAnalysisToolScenario
                  value={scenarioId}
                  options={
                    scenarioOptionsHash?.[portfolioId]?.filter(
                      option =>
                        option.value === scenarioId || portfolioScenarioHash[portfolioId]?.includes(option.value)
                    ) || []
                  }
                  index={index}
                  setIds={setIds}
                  isDisabled={loading}
                />
                {ids.length > 2 && (
                  <StyledDeleteCardButton
                    variant="primary-link"
                    icon={<IconDelete />}
                    data-index={index}
                    tooltipKey="Delete"
                    onClick={handleDeleteBtnClick}
                    size="small"
                    aria-label="Delete"
                    disabled={loading}
                  />
                )}
              </StyledCard>
            </Col>
          ))}
          <StyledButtonContainer>
            <Button
              tooltipKey="Add set"
              icon={<IconPlus />}
              variant="primary"
              onClick={handleAddSetClick}
              disabled={!Object.keys(portfolioScenarioHash).length || loading}
            />
          </StyledButtonContainer>
        </Row>
        <Row>
          <Col xs={3}>
            <SelectChartType value={typeOption.value} onChange={setTypeOption} isDisabled={loading} />
          </Col>
        </Row>
      </>
      <ContentContainer>
        <ContentCard>
          {typeOption.key !== ChartTypeKeys.INVESTMENTS && (
            <ChartAnalysis
              key={typeOption.key}
              ids={ids}
              typeOption={typeOption}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          <ChartInvestmentSummary ids={ids} setLoading={setLoading} typeOption={typeOption} />
        </ContentCard>
      </ContentContainer>
    </DataTableTemplate>
  );
};

const StyledCard = styled.div`
  position: relative;
  padding: 10px;
  border: 1px solid #e1e6ec;
  border-radius: 8px;

  > small:first-of-type {
    margin-bottom: 10px;
  }
`;

const StyledDeleteCardButton = styled(Button)`
  position: absolute;
  right: 0px;
  bottom: 0.5rem;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default ViewAnalysisTool;
