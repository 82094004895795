import Highcharts from 'highcharts';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import Chart from 'components/_charts/Chart';
import { fetchSummaryPlanAction } from 'modules/investment';
import { portfolioOptionsSelector, scenarioOptionsHashSelector } from 'modules/options/selectors';
import { ChartTypeKeys, ChartTypesOption } from './SelectChartType';

type Hash = Record<string, { name: string; data: Investment.Root['summaryPlanItems'] }> | null;

interface ChartDataProps {
  series: Omit<Shared.SeriesOptionsType, 'type'>[];
  categories: number[];
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
}

interface Props {
  typeOption: ChartTypesOption;
  ids: number[][];
  setLoading: React.Dispatch<boolean>;
}

const ChartInvestmentSummary: React.FC<Props> = ({ typeOption, ids, setLoading }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl, numberFormat } = useLocale();
  const isInvestments = typeOption.key === ChartTypeKeys.INVESTMENTS;

  const [currency, setCurrency] = useState<string>('');
  const [itemsHashStore, setItemsHashStore] = useState<Hash>(null);
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);

  const portfolioOptions = useSelector(portfolioOptionsSelector);
  const scenarioOptionsHash = useSelector(scenarioOptionsHashSelector);
  const getSeriesName = useCallback(
    ([portfolioId, scenarioId]: [number, number]) => {
      const portfolioOptionLabel = portfolioOptions!.find(option => option.value === portfolioId)?.label;
      const scenarioOptionLabel = scenarioOptionsHash?.[portfolioId!]!.find(
        option => option.value === scenarioId
      )?.label;
      return `${portfolioOptionLabel} - ${scenarioOptionLabel}`;
    },
    [portfolioOptions, scenarioOptionsHash]
  );

  const getStoreKey = useCallback(([portfolioId, scenarioId]: [number, number]) => `${portfolioId}_${scenarioId}`, []);

  const seriesItems = useMemo(() => {
    const items = ids
      .map(([portfolioId, scenarioId]) => itemsHashStore?.[getStoreKey([portfolioId, scenarioId])])
      .filter(Boolean);
    return items.length === ids.length ? items : null;
  }, [ids, itemsHashStore, getStoreKey]);

  useEffect(() => {
    if (!isInvestments || !ids.length) return;
    const hash: Hash = {};
    let currency: string = '';
    (async function fetch(index = 0): Promise<void> {
      if (index >= ids.length) {
        if (currency) setCurrency(currency);
        if (Object.keys(hash).length) setItemsHashStore(prev => ({ ...prev, ...hash }));
        setLoading(false);
        return;
      }

      const [portfolioId, scenarioId] = ids[index];
      const storeKey = getStoreKey([portfolioId, scenarioId]);

      if (itemsHashStore?.[storeKey]) return fetch(index + 1);

      try {
        setLoading(true);
        const action = await dispatch(
          fetchSummaryPlanAction({
            portfolioId,
            scenarioId,
            skipStoreUpdate: true,
            inputFilters: { startYear: new Date().getFullYear(), endYear: 2100 },
          })
        );
        if (!currency) currency = action.payload?.summaryPlanMetaCurrency || '';
        hash[storeKey] = {
          name: getSeriesName([portfolioId, scenarioId]),
          data: action.payload?.summaryPlanItems || [],
        };
      } finally {
        fetch(index + 1);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvestments, ids, dispatch, getSeriesName]); // itemsHashStore excluded

  useEffect(() => {
    if (!isInvestments || !seriesItems) return;
    console.log('ChartInvestmentSummary - setChartData');
    const items = seriesItems.find(elem => elem?.data.length)?.data || [];
    const categories = [...new Set(items.map(item => item.suggested_replacement_year))];

    const series = seriesItems.map(item => ({
      name: item?.name,
      data: item?.data.map(item => item.replacement_cost),
    }));

    setChartData({
      series,
      categories,
      title: 'Yearly summary',
      yAxisTitle: 'Investment cost ({{currency}})',
      xAxisTitle: 'Investment year',
    });
  }, [isInvestments, seriesItems]);

  const options: Highcharts.Options = useMemo(
    () => ({
      chart: { type: 'column', zoomType: 'y' },
      title: {
        text: getIntl(chartData?.title || ''),
      },
      xAxis: {
        title: {
          text: getIntl(chartData?.xAxisTitle || ''),
        },
        categories: chartData?.categories,
      },
      yAxis: {
        min: 0,
        title: {
          text: getIntl(chartData?.yAxisTitle || '', { currency }),
        },
      },
      tooltip: {
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return [
            `<b>${this.series.name}</b><br/>`,
            `${getIntl(chartData?.xAxisTitle || '')}: ${this.x}<br/>`,
            `${getIntl(chartData?.yAxisTitle || '', { currency })}: ${numberFormat(this.y, {
              minimumFractionDigits: 2,
            })}`,
          ].join('');
        },
      },
      series: chartData?.series,
    }),
    [chartData, currency, getIntl, numberFormat]
  ) as Highcharts.Options;

  if (!isInvestments) return null;
  return (
    <Chart
      options={chartData ? options : null}
      dataMarker="chart_analysis_tool_investment"
      height="calc(100vh - 355px)"
    />
  );
};

export default ChartInvestmentSummary;
