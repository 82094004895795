import React from 'react';
import { useLocale } from 'hooks';
import { AppErrorsTypes } from 'constants/index';

const PageAppExceptions: React.FC<{ appError?: App.AppErrorsTypes }> = ({
  appError = AppErrorsTypes.PAGE_NOT_FOUND,
}) => {
  const { getIntl } = useLocale();

  const getStatus = () => {
    switch (appError) {
      case AppErrorsTypes.FETCH_USER_ERROR:
        return 500;
      default:
        return 404;
    }
  };

  const getTitle = () => {
    switch (appError) {
      case AppErrorsTypes.FETCH_USER_ERROR:
        return 'Oops! User not found';
      case AppErrorsTypes.TENANT_NOT_FOUND:
        return 'Oops! Tenant not found';
      case AppErrorsTypes.NO_ASSIGNED_TENANTS:
        return 'Oops! No assigned tenants';
      case AppErrorsTypes.NO_SCENARIOS:
        return 'Oops! Scenarios not found';
      case AppErrorsTypes.NO_SIMULATIONS:
        return 'Oops! Simulations not found';
      default:
        return 'Oops! Page not found';
    }
  };

  const getMessage = () => {
    switch (appError) {
      case AppErrorsTypes.FETCH_USER_ERROR:
        return 'We could not find the user you were looking for. Please contact administrator';
      case AppErrorsTypes.TENANT_NOT_FOUND:
        return 'We could not find the tenant you were looking for. Meanwhile, you may use the user menu to select another tenant';
      case AppErrorsTypes.NO_ASSIGNED_TENANTS:
        return 'We could not find any assigned tenants to your account. Please contact administrator';
      case AppErrorsTypes.NO_SCENARIOS:
        return 'We could not find scenarios. Meanwhile, you may use the app menu to select another portfolio';
      case AppErrorsTypes.NO_SIMULATIONS:
        return 'We could not find simulations. Meanwhile, you may use the app menu to select another portfolio and/or scenarios';
      default:
        return 'We could not find the page you were looking for. Meanwhile, you may use the dashboard menu';
    }
  };

  return (
    <div className="error-page mt-0 pt-5">
      <h2 className="headline text-danger">{getStatus()}</h2>
      <div className="error-content">
        <h3>
          <i className="fas fa-exclamation-triangle text-danger"></i> {getIntl(getTitle())}.
        </h3>
        <p>{getIntl(getMessage())}.</p>
      </div>
    </div>
  );
};

export default PageAppExceptions;
