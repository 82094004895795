import styled from 'styled-components';
import React, { useMemo } from 'react';
import { PermissionGroups, PermissionsTypesLocalesMap } from 'constants/index';

const PermissionsTypesTableCell: React.FC<{ permissions: Permissions.Permissions }> = ({ permissions }) => {
  const permissionsByGroups = useMemo(
    () =>
      (Object.keys(permissions) as Permissions.PermissionsTypes[]).reduce(
        (acc: Record<PermissionGroups, any>, key) => {
          const { group } = PermissionsTypesLocalesMap[key as Permissions.PermissionsTypes] || {};
          acc[group].total = (acc[group].total || 0) + 1;
          acc[group].enabled = (acc[group].enabled || 0) + Number(permissions[key]);
          return acc;
        },
        Object.values(PermissionGroups).reduce(
          (acc, key) => {
            acc[key] = [];
            return acc;
          },
          {} as Record<PermissionGroups, any[]>
        )
      ),
    [permissions]
  );

  return (
    <StyledCell>
      {Object.entries(permissionsByGroups).map(([key, value]) => {
        const percentage = (value.enabled / value.total) * 100;
        return (
          <StyledBadge key={key}>
            <BadgeText>
              {key} ({value.enabled}/{value.total})
            </BadgeText>
            <IndicatorContainer>
              <Indicator $percentage={percentage} style={{ width: `${percentage}%` }} />
            </IndicatorContainer>
          </StyledBadge>
        );
      })}
    </StyledCell>
  );
};

const StyledCell = styled.td`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

const StyledBadge = styled.span`
  background-color: ${props => props.theme.colors.purple25};
  border-radius: 8px;
  padding: 6px 8px;
  position: relative;
  font-size: 85%;
  font-weight: 500;
  white-space: nowrap;
`;

const BadgeText = styled.div`
  margin-bottom: 4px;
`;

const IndicatorContainer = styled.div`
  background-color: ${props => props.theme.colors.grey50};
  border-radius: 5px;
  height: 4px;
  overflow: hidden;
`;

const Indicator = styled.div<{ $percentage: number }>`
  background-color: ${props => props.theme.colors.purple150};
  height: 100%;
  width: ${props => props.$percentage}%;
`;

export default PermissionsTypesTableCell;
