import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { rightsSelector, rightsCountSelector } from 'modules/customers/selectors';
import { fetchRightsAction } from 'modules/customers';
import { setLayoutAction } from 'modules/layouts';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { IconDetails } from '@utiligize/shared/resources';
import { DataTable, SolvedOption, Button } from 'components/_common';
import { PaginationType, isProduction } from 'constants/index';

const TableRights: React.FC = () => {
  const { numberFormat, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const paginationType = PaginationType.CUSTOMERS_RIGHTS;

  const items = useSelector(rightsSelector);
  const count = useSelector(rightsCountSelector);
  const portfolioId = useSelector(portfolioIdSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchRightsAction(params)).then(
        (action: Shared.ReduxAction<{ rightsHash: Type.Hash<Customers.Rights> }>) =>
          Object.values(action.payload?.rightsHash || {})
      ),
    [dispatch]
  );

  const handleSeeProfileBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const installationsID: string | null = event.currentTarget.getAttribute('data-id');
      if (!installationsID) return;
      dispatch(
        setLayoutAction({
          customersRightsProfileModalId: installationsID,
          // CustomerRightsProfileModal -> AssetTimeSeriesChart must default to the current year
          selectedChartYear: new Date().getFullYear(),
        })
      );
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={paginationType}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 261px)"
      waitForDependencies={!portfolioId}
      triggerTableUpdateDeps={[portfolioId]}
    >
      {items?.map((item: Customers.Rights) => (
        <tr key={item.id}>
          <td>{item.installation_number}</td>
          <td>{item.address || '-'}</td>
          <td>{item.substation || '-'}</td>
          <td className="text-center">
            <SolvedOption uuid={item.id} checked={item.ignore} paginationType={paginationType} />
          </td>
          <td>{item.explanation || '-'}</td>
          <td>{numberFormat(item.amps_rights, { fallback: '-' })}</td>
          {!isProduction && <td>{numberFormat(item.amps_rights_prod, { fallback: '-' })}</td>}
          <td>{numberFormat(item.amps, { fallback: '-' })}</td>

          <td>{numberFormat(item.kva) ?? '-'}</td>
          {!isProduction && <td>{numberFormat(item.kva_rights, { fallback: '-' })}</td>}
          <td>{item.time ? dateFormat(item.time, 'lll') : '-'}</td>

          <td>{numberFormat(item.utilization, { fallback: '-' })}</td>
          <td className="text-right">
            <Button
              tooltipKey="Profile"
              icon={<IconDetails />}
              data-id={item.id}
              onClick={handleSeeProfileBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableRights;
