import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { Spinner } from 'components/_common';
import { plumberAPI } from 'constants/index';

interface Props {
  className?: string;
  dataMarker?: string;
  title: string;
  src: string;
  height?: string;
}

const ChartIframe: React.FC<Props> = ({ className = '', dataMarker = '', title, src, height = '70vh' }) => {
  const [srcDoc, setSrcDoc] = useState(undefined);
  const Iframe = <StyledIframe data-marker={dataMarker} title={title} srcDoc={srcDoc} />;

  useEffect(() => {
    setSrcDoc(undefined);
    if (!src) return;
    plumberAPI
      .get(src)
      .then((res: any) => setSrcDoc(res.data))
      .catch(console.error);
  }, [src]);

  return (
    <StyledCardBody className={className} data-height={height}>
      {!srcDoc && <Spinner isInFullHeightContainer />}
      {srcDoc && Iframe}
    </StyledCardBody>
  );
};

const StyledCardBody = styled.div<{ 'data-height': string }>`
  height: ${props => props['data-height']};
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 1px solid ${props => props.theme.colors.grey100};
`;

export default ChartIframe;
