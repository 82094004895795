import React, { useEffect, useMemo, useState } from 'react';
import { useLocale } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFutureChartData } from 'modules/networkLoading';
import { futureChartDataHashSelector } from 'modules/networkLoading/selectors';
import Chart, { getBaseOptions } from 'components/_charts/Chart';
import { ChartTypesOption, ChartTypeKeys } from './SelectChartType';
import { TransformerVoltages } from 'constants/index';

export interface Props {
  typeOption: ChartTypesOption;
  ids: number[][];
  loading: boolean;
  setLoading: React.Dispatch<boolean>;
}

const ChartAnalysis: React.FC<Props> = ({ ids, loading, setLoading, typeOption }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const voltage = TransformerVoltages['10/0.4kV transformer'];
  const [failedKeys, setFailedKeys] = useState<string[]>([]);

  const futureChartDataHash = useSelector(futureChartDataHashSelector);

  useEffect(() => {
    if (loading || typeOption.key === ChartTypeKeys.INVESTMENTS || !ids.length) return;
    ids.some(([portfolioId, scenarioId]) => {
      const storeKey = `${typeOption.asset_class}_${voltage}_${portfolioId}_${scenarioId}`;
      if (!futureChartDataHash?.[storeKey] && !failedKeys.includes(storeKey)) {
        setLoading(true);
        dispatch(
          fetchFutureChartData({ asset_class: typeOption.asset_class!, voltage, portfolioId, scenarioId, storeKey })
        )
          .catch(() => setFailedKeys(prev => [...prev, storeKey]))
          .finally(() => setLoading(false));
        return true;
      }
      return false;
    });
    // futureChartDataHash excluded due to loading state approach
  }, [dispatch, voltage, ids, loading, typeOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const chartData = useMemo(() => {
    if (typeOption.key === ChartTypeKeys.INVESTMENTS) return null;

    // Initialize a flag to track missing chart data
    let hasMissingData = false;
    return ids.reduce((acc: any, [portfolioId, scenarioId]) => {
      const storeKey = `${typeOption.asset_class}_${voltage}_${portfolioId}_${scenarioId}`;
      const chartData = futureChartDataHash?.[storeKey]?.[typeOption.index!];
      if (!chartData || hasMissingData) {
        hasMissingData = true;
        return null;
      }
      if (!acc) return { ...chartData };
      return { ...acc, series: [...acc.series, ...chartData.series!] };
    }, null);
  }, [voltage, futureChartDataHash, ids, typeOption]);

  const baseOptions = getBaseOptions(getIntl, {
    title: chartData?.title,
    xAxisTitle: chartData?.xAxisTitle,
    yAxisTitle: chartData?.yAxisTitle,
  });

  const options = useMemo(
    () => ({
      ...baseOptions,
      xAxis: {
        ...baseOptions.xAxis,
        minTickInterval: 1,
      },
      tooltip: {
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        shared: true,
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return `<b>${this.x}</b><br>${this.points?.map(point => `${point.series.name} <b>${point.y}%</b><br>`).join('')}`;
        },
      },
      series: chartData?.series?.map((s: any) => ({ ...s, name: getIntl(s.name || '') })) || [],
    }),
    [baseOptions, chartData?.series, getIntl]
  ) as unknown as Highcharts.Options;

  return (
    <Chart options={chartData?.series ? options : null} dataMarker="chart_analysis_tool" height="calc(100vh - 355px)" />
  );
};

export default ChartAnalysis;
