import classNames from 'classnames';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import React, { useCallback } from 'react';
import { useLocale } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { setLayoutAction } from 'modules/layouts';
import {
  isPageHistorySelector,
  isPageNewLoadSelector,
  isRouteActiveSelectorFactory,
  mapPanelSelectedAssetUUIDSelector,
} from 'modules/router/selectors';
import {
  appIsAdminUserSelector,
  appIsSupervisorUserSelector,
  externalTimeSystemSelector,
  isMaintenancePlanningEnabledSelector,
} from 'modules/app/selectors';
import { featureTogglesSelector, isSidebarOpenSelector, singleDiagramEnabledSelector } from 'modules/layouts/selectors';
import PopoverAppSettings from './PopoverAppSettings';
import PopoverReports from './PopoverReports';
import NavigationSearch from './NavigationSearch';
import SidebarHelpButton from './SidebarHelpButton';
import PopoverUserSettings from './PopoverUserSettings';
import { Button, Alert } from 'components/_common';
import { Routes, StorageKeys, isProduction } from 'constants/index';
import {
  IconChart,
  IconRepeatedTasks,
  IconTransformers,
  IconCables,
  IconTotalLoading,
  IconManufacturers,
  IconInstructions,
  IconToolInspection,
  IconStatistics,
  IconAnswer,
  IconCalendar,
  IconFuse,
  IconManageDERs,
  IconGlobalDevelopment,
  IconRawData,
  IconMeter,
  IconTool,
  IconList,
  IconFilePlus,
  IconSettings,
  IconCoinsHand,
  IconUsers2,
  IconTimeOverview,
  IconFileSearch,
  IconServer,
  IconAlertTriangle,
  IconCheckCircle,
  IconFileCheck2,
  IconBarChart2,
  IconLayers,
  IconUser,
  IconHistory,
  IconClipboardCheck,
  IconVoltageDrop,
  IconCO2,
  IconEmissions,
  IconEmissions2,
  IconEmissionForecast,
  IconMap,
  IconDataFlow4,
} from '@utiligize/shared/resources';
import { isMapConfigFetchedSelector } from 'modules/map/selectors';

interface Props {
  appCurrentUserPermissions: Permissions.Permissions;
}

const Navigation: React.FC<Props> = ({ appCurrentUserPermissions }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const isMaintenancePlanningEnabled = useSelector(isMaintenancePlanningEnabledSelector);
  const isPageAdmin = useSelector(isRouteActiveSelectorFactory(Routes.Admin, false));
  const isPageNetworkLoading = useSelector(isRouteActiveSelectorFactory(Routes.NetworkLoading, false));
  const isPageCO2e = useSelector(isRouteActiveSelectorFactory(Routes.CO2e, false));
  const isPageInvestment = useSelector(isRouteActiveSelectorFactory(Routes.Investment, false));
  const isPageMaintenance = useSelector(isRouteActiveSelectorFactory(Routes.Maintenance, false));
  const isPageAssetModels = useSelector(isRouteActiveSelectorFactory(Routes.AssetModels, false));
  const isPageReliability = useSelector(isRouteActiveSelectorFactory(Routes.Reliability, false));
  const isPageForms = useSelector(isRouteActiveSelectorFactory(Routes.Forms, false));
  const isPageTasks = useSelector(isRouteActiveSelectorFactory(Routes.Tasks, false));
  const isPageMeters = useSelector(isRouteActiveSelectorFactory(Routes.Meters));
  const isPageHistory = useSelector(isPageHistorySelector);
  const isPageNewLoad = useSelector(isPageNewLoadSelector);
  const isPageSetup = useSelector(isRouteActiveSelectorFactory(Routes.Setup, false));
  const externalTimeSystem = useSelector(externalTimeSystemSelector);
  const appIsAdminUser = useSelector(appIsAdminUserSelector);
  const appIsSupervisorUser = useSelector(appIsSupervisorUserSelector);
  const isSidebarOpen = useSelector(isSidebarOpenSelector);
  const isPageMap = useSelector(isRouteActiveSelectorFactory(Routes.Map));
  const isPageDataQuality = useSelector(isRouteActiveSelectorFactory(Routes.DataQuality, false));
  const singleDiagramEnabled = useSelector(singleDiagramEnabledSelector);
  const featureToggles = useSelector(featureTogglesSelector);
  const isVoltageDropEnabled = featureToggles[StorageKeys.FEATURE_TOGGLE_VOLTAGE_DROP];
  const isMapConfigFetched = useSelector(isMapConfigFetchedSelector);
  const isUAVEnabled = Boolean(useSelector(mapPanelSelectedAssetUUIDSelector));

  const handleMapButtonClick = useCallback(() => {
    dispatch(setLayoutAction({ singleDiagramEnabled: false }));
  }, [dispatch]);

  const handleSingleDiagramButtonClick = useCallback(async () => {
    if (isUAVEnabled) await dispatch(push(generatePath(Routes.Map)));
    dispatch(
      setLayoutAction({
        singleDiagramEnabled: true,
        isExtendedGridEnabled: false,
        isMapConnectionsEnabled: false,
        mapPanelEnabled: true,
      })
    );
  }, [dispatch, isUAVEnabled]);

  return (
    <StyledContainer className="main-header navbar" $isSidebarOpen={isSidebarOpen}>
      <StyledList>
        {isPageAdmin && appIsAdminUser && (
          <>
            <li>
              <Button icon={<IconUser />} labelKey="Users" linkProps={{ to: Routes.Admin, exact: true }} />
            </li>
            <li>
              <Button
                icon={<IconUsers2 />}
                labelKey="Groups"
                linkProps={{ to: Routes.AdminGroups, exact: true }}
                marginLeft
              />
            </li>
            {isMaintenancePlanningEnabled && (
              <li>
                <Button
                  icon={<IconFileSearch />}
                  labelKey="Access logs"
                  linkProps={{ to: Routes.AdminAccessLogs, exact: true }}
                  marginLeft
                />
              </li>
            )}
            {appIsSupervisorUser && (
              <li>
                <Button
                  icon={<IconLayers />}
                  labelKey="Supervisor"
                  linkProps={{ to: Routes.AdminSupervisor, exact: false }}
                  marginLeft
                />
              </li>
            )}
          </>
        )}
        {isPageCO2e && (
          <>
            <li>
              <Button
                icon={<IconCO2 />}
                labelKey="Overview"
                dataMarker="co2e_overview"
                linkProps={{ to: Routes.CO2e, exact: true }}
              />
            </li>
            <li>
              <Button
                icon={<IconEmissionForecast />}
                dataMarker="co2e_emissions_forecast"
                labelKey="Emissions forecast"
                linkProps={{ to: Routes.CO2eEmissionsForecast, exact: true }}
                marginLeft
              />
            </li>
            <li>
              <Button
                icon={<IconEmissions />}
                dataMarker="co2e_detailed_emissions"
                labelKey="Detailed emissions"
                linkProps={{ to: Routes.CO2eDetailedEmissions, exact: true }}
                marginLeft
              />
            </li>
            <li>
              <Button
                icon={<IconEmissions2 />}
                dataMarker="co2e_emissions_by_scope"
                labelKey="Emissions by scope"
                linkProps={{ to: Routes.CO2eEmissionsByScope, exact: true }}
                marginLeft
              />
            </li>
          </>
        )}
        {isPageReliability && appCurrentUserPermissions.isReliabilityEnabled && (
          <li>
            <Button
              dataMarker="reinvestmnet_reliability_saifi_cml"
              labelKey="Saifi & CML"
              icon={<IconBarChart2 />}
              linkProps={{ to: Routes.Reliability, exact: true }}
            />
          </li>
        )}
        {isPageNetworkLoading && appCurrentUserPermissions.isNetworkLoadingEnabled && (
          <>
            <li>
              <Button
                dataMarker="network_loading__transformers"
                icon={<IconTransformers />}
                labelKey="Transformers"
                linkProps={{ to: Routes.NetworkLoading, exact: true }}
              />
            </li>
            <li>
              <Button
                dataMarker="network_loading__pipes_cables"
                icon={<IconCables />}
                labelKey="Cables"
                linkProps={{ to: Routes.NetworkLoadingCables, exact: true }}
                marginLeft
              />
            </li>
            {featureToggles[StorageKeys.FEATURE_TOGGLE_NETWORK_LOADING_FUSES] && (
              <li>
                <Button
                  dataMarker="network_loading__fuses"
                  icon={<IconFuse />}
                  labelKey="Fuses"
                  linkProps={{ to: Routes.NetworkLoadingFuses, exact: true }}
                  marginLeft
                />
              </li>
            )}
            <li>
              <Button
                dataMarker="network_loading__total"
                icon={<IconTotalLoading />}
                labelKey="Total loading"
                linkProps={{ to: Routes.NetworkLoadingTotalLoading, exact: false }}
                marginLeft
              />
            </li>
            {isVoltageDropEnabled && (
              <li>
                <Button
                  dataMarker="network_loading__voltage_drop"
                  icon={<IconVoltageDrop />}
                  labelKey="Voltage drop"
                  linkProps={{ to: Routes.NetworkLoadingViewVoltageDrop, exact: false }}
                  marginLeft
                />
              </li>
            )}
          </>
        )}
        {isPageDataQuality && appCurrentUserPermissions.isDataQualityEnabled && (
          <>
            <li>
              <Button
                dataMarker="data_quality__issues"
                icon={<IconAlertTriangle />}
                labelKey="Issues"
                linkProps={{ to: Routes.DataQuality, exact: true }}
              />
            </li>
            <li>
              <Button
                dataMarker="data_quality__customers_connected"
                icon={<IconCheckCircle />}
                labelKey="Customers connected"
                linkProps={{ to: Routes.DataQualityCustomersConnected, exact: true }}
                marginLeft
              />
            </li>
            <li>
              <Button
                dataMarker="data_quality__meters"
                icon={<IconMeter />}
                labelKey="Meters"
                linkProps={{ to: generatePath(Routes.DataQualityMeters), exact: true }}
                marginLeft
              />
            </li>
            <li>
              <Button
                dataMarker="data_quality__age_statistics"
                icon={<IconChart />}
                labelKey="Age statistics"
                linkProps={{ to: Routes.DataQualityStatistics, exact: false }}
                marginLeft
              />
            </li>
          </>
        )}
        {isPageSetup && appCurrentUserPermissions.isSetupEnabled && (
          <>
            <li>
              <Button
                dataMarker="setup__forecasted_scenarios"
                icon={<IconServer />}
                labelKey="Forecasted scenarios"
                linkProps={{ to: Routes.Setup, exact: true }}
              />
            </li>
            <li>
              <Button
                icon={<IconCoinsHand />}
                labelKey="Investment"
                dataMarker="setup__investment"
                linkProps={{ to: Routes.SetupInvestment, exact: false }}
                marginLeft
              />
            </li>
            <li>
              <Button
                icon={<IconCO2 />}
                labelKey="CO₂e"
                dataMarker="setup__co2e"
                linkProps={{ to: Routes.SetupCO2e, exact: true }}
                marginLeft
              />
            </li>
            <li>
              <Button
                dataMarker="setup__raw_data"
                icon={<IconRawData />}
                labelKey="Raw data"
                linkProps={{ to: Routes.SetupRawData, exact: true }}
                marginLeft
              />
            </li>
            <li>
              <Button
                dataMarker="setup__calculation_schedule"
                icon={<IconCalendar />}
                labelKey="Calculation schedule"
                linkProps={{ to: Routes.SetupCalculationSchedule, exact: true }}
                marginLeft
              />
            </li>
          </>
        )}
        {isPageNewLoad && appCurrentUserPermissions.isNewLoadEnabled && (
          <>
            <li>
              <Button
                dataMarker="new_load__manage_ders"
                icon={<IconManageDERs />}
                labelKey="Manage DERs"
                linkProps={{ to: Routes.NewLoad, exact: true }}
              />
            </li>
            <li>
              <Button
                icon={<IconGlobalDevelopment />}
                labelKey="Global development"
                dataMarker="new_load__global_development"
                linkProps={{ to: Routes.NewLoadGlobalDevelopment, exact: true }}
                marginLeft
              />
            </li>
          </>
        )}
        {isPageInvestment && appCurrentUserPermissions.isInvestmentEnabled && (
          <>
            <li>
              <Button
                dataMarker="investment__summary"
                icon={<IconFileCheck2 />}
                labelKey="Summary"
                linkProps={{ to: Routes.Investment, exact: true }}
              />
            </li>
            <li>
              <Button
                dataMarker="investment__detailed_investments"
                icon={<IconCoinsHand />}
                labelKey="Detailed investments"
                linkProps={{ to: Routes.InvestmentDetailedInvestments, exact: true }}
                marginLeft
              />
            </li>
          </>
        )}
        {isPageTasks && appCurrentUserPermissions.isTasksEnabled && (
          <>
            <li>
              <Button
                dataMarker="maintenance_planning__tasks"
                icon={<IconClipboardCheck />}
                labelKey="Tasks"
                linkProps={{ to: generatePath(Routes.Tasks), exact: true }}
              />
            </li>
            <li>
              <Button
                dataMarker="maintenance_planning__employees"
                icon={<IconUsers2 />}
                labelKey="Employees"
                marginLeft
                linkProps={{ to: Routes.TasksEmployees, exact: false }}
              />
            </li>
            <li>
              <Button
                dataMarker="maintenance_planning__time_overview"
                icon={<IconTimeOverview />}
                labelKey="Time overview"
                marginLeft
                linkProps={{ to: generatePath(Routes.TasksTimeOverview), exact: false }}
              />
            </li>
            {externalTimeSystem && (
              <li>
                <Button
                  dataMarker="maintenance_planning__repeated_tasks"
                  icon={<IconRepeatedTasks />}
                  labelKey="Repeated tasks"
                  marginLeft
                  linkProps={{ to: Routes.TasksRepeatedTasks, exact: false }}
                />
              </li>
            )}
          </>
        )}
        {isPageMeters && appCurrentUserPermissions.isSmartMetersEnabled && (
          <>
            <li>
              <Button
                dataMarker="maintenance_planning__meter_actions"
                icon={<IconMeter />}
                labelKey="Smart meter actions"
                linkProps={{ to: generatePath(Routes.Meters), exact: true }}
              />
            </li>
            <li>
              <Button
                dataMarker="maintenance_planning__population"
                icon={<IconUsers2 />}
                labelKey="Population"
                linkProps={{ to: generatePath(Routes.MetersPopulation), exact: true }}
                marginLeft
              />
            </li>
          </>
        )}
        {isPageMaintenance && appCurrentUserPermissions.isMaintenanceEnabled && (
          <>
            <li>
              <Button
                dataMarker="maintenance_planning__create_plans"
                icon={<IconSettings />}
                labelKey="Create plans"
                linkProps={{ to: Routes.Maintenance, exact: true }}
              />
            </li>
            {featureToggles[StorageKeys.FEATURE_TOGGLE_MAINTENANCE_PLAN_CNAIM] && (
              <li>
                <Button
                  dataMarker="maintenance_planning__plan_cnaim"
                  icon={<IconSettings />}
                  labelKey="Plan (CNAIM)"
                  linkProps={{ to: Routes.MaintenancePlanCNAIM, exact: true }}
                  marginLeft
                />
              </li>
            )}

            <li>
              <Button
                dataMarker="maintenance_planning__plan_summary"
                icon={<IconTool />}
                labelKey="Plan summary"
                linkProps={{ to: Routes.MaintenancePlanSummary, exact: true }}
                marginLeft
              />
            </li>
            <li>
              <Button
                dataMarker="maintenance_planning__detailed_plan"
                icon={<IconCalendar />}
                labelKey="Detailed plan"
                linkProps={{ to: Routes.MaintenanceDetailedPlan, exact: true }}
                marginLeft
              />
            </li>
            <li>
              <Button
                dataMarker="maintenance_planning__statistics"
                icon={<IconStatistics />}
                labelKey="Statistics"
                linkProps={{ to: Routes.MaintenanceStatistics, exact: false }}
                marginLeft
              />
            </li>
          </>
        )}
        {isPageAssetModels && appCurrentUserPermissions.isAssetModelsEnabled && (
          <>
            <li>
              <Button
                dataMarker="maintenance_planning__asset_models"
                icon={<IconList />}
                labelKey="Asset models"
                linkProps={{ to: Routes.AssetModels, exact: true }}
              />
            </li>
            <li>
              <Button
                dataMarker="maintenance_planning__manufacturers"
                icon={<IconManufacturers />}
                labelKey="Manufacturers"
                linkProps={{ to: Routes.AssetModelsManufacturers, exact: false }}
                marginLeft
              />
            </li>
          </>
        )}
        {isPageForms && appCurrentUserPermissions.isFormsEnabled && (
          <>
            <li>
              <Button
                dataMarker="maintenance_planning__forms"
                icon={<IconFilePlus />}
                labelKey="Forms"
                linkProps={{ to: generatePath(Routes.Forms), exact: true }}
              />
            </li>
            <li>
              <Button
                dataMarker="maintenance_planning__instructions"
                icon={<IconInstructions />}
                labelKey="Instructions"
                linkProps={{ to: generatePath(Routes.Instructions), exact: true }}
                marginLeft
              />
            </li>
            <li>
              <Button
                dataMarker="maintenance_planning__tool_inspection"
                icon={<IconToolInspection />}
                labelKey="Tool inspection"
                linkProps={{ to: generatePath(Routes.Inspections), exact: true }}
                marginLeft
              />
            </li>
          </>
        )}
        {isPageHistory && appCurrentUserPermissions.isHistoryEnabled && (
          <>
            <li>
              <Button
                dataMarker="maintenance_planning__history"
                icon={<IconHistory />}
                labelKey="History"
                linkProps={{ to: generatePath(Routes.History), exact: true }}
              />
            </li>
            <li>
              <Button
                dataMarker="maintenance_planning__answers"
                icon={<IconAnswer />}
                labelKey="Answers"
                linkProps={{ to: generatePath(Routes.HistoryAnswers), exact: true }}
                marginLeft
              />
            </li>
          </>
        )}
        {isPageMap &&
          appCurrentUserPermissions.isMapEnabled &&
          appCurrentUserPermissions.isSingleLineDiagramEnabled &&
          isMapConfigFetched && (
            <>
              <Button
                dataMarker="main_map"
                className={classNames({ active: !singleDiagramEnabled })}
                onClick={handleMapButtonClick}
                labelKey="Map"
                icon={<IconMap />}
              />
              <Button
                dataMarker="single_line_diagram"
                className={classNames({ active: singleDiagramEnabled })}
                onClick={handleSingleDiagramButtonClick}
                labelKey="Single line diagram"
                marginLeft
                icon={<IconDataFlow4 />}
              />
              {singleDiagramEnabled && (
                <StyledBetaAlert variant="warning">
                  <IconAlertTriangle />
                  <small>
                    <strong>{getIntl('Note')}:</strong> {getIntl('This is a beta feature')}
                  </small>
                </StyledBetaAlert>
              )}
            </>
          )}
      </StyledList>
      <div className="ml-auto d-flex align-items-center flex-shrink-0">
        <PopoverAppSettings />
        {!isProduction && <NavigationSearch />}
        <PopoverReports />
        {!isProduction && <SidebarHelpButton />}
        <PopoverUserSettings />
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.nav<{ $isSidebarOpen: boolean }>`
  &&&&& {
    padding: 10px;
    background: ${props => props.theme.colors.white};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1020;
    flex-wrap: nowrap;
    ${({ $isSidebarOpen }) => !$isSidebarOpen && `margin-left: 60px!important`};
  }
`;

const StyledList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  padding-right: 8px;
  flex-shrink: 0;
`;

const StyledBetaAlert = styled(Alert)`
  margin: 0 0 0 10px;
  padding: 0 10px 0 8px;
  display: flex;
  align-items: center;

  > svg {
    fill: currentColor;
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }
`;

export default Navigation;
