import { handleActions, combineActions } from 'redux-actions';
import {
  fetchDetailedPlanAction,
  fetchAssetCategoriesOptionsAction,
  fetchReplacementReasonOptionsAction,
  applyReplacementReasonFiltersAction,
  fetchGridZonesOptionsAction,
  fetchVoltageLevelsOptionsAction,
  fetchSummaryPlanAction,
  fetchSummaryPlanSummaryGroupOptionsAction,
} from './actions';

const initialState: Investment.Root = {
  detailedPlanCount: 0,
  detailedPlanMetaCurrency: '',
  detailedPlanItems: [],
  assetCategoriesOptionsHash: {},
  replacementReasonOptionsHash: {},
  voltageLevelsOptionsHash: {},
  gridZonesOptionsHash: {},
  summaryPlanItems: [],
  summaryPlanCount: 0,
  summaryPlanMetaCurrency: '',
  summaryPlanGroupOptionsHash: {},
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(
    fetchDetailedPlanAction,
    fetchAssetCategoriesOptionsAction,
    fetchReplacementReasonOptionsAction,
    fetchGridZonesOptionsAction,
    fetchVoltageLevelsOptionsAction,
    fetchSummaryPlanAction,
    fetchSummaryPlanSummaryGroupOptionsAction
  ) as any]: {
    next: (
      state: Investment.Root,
      action: Shared.ReduxAction<Partial<Investment.Root> & { skipStoreUpdate: true | undefined }>
    ): Investment.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export {
  fetchDetailedPlanAction,
  fetchAssetCategoriesOptionsAction,
  fetchReplacementReasonOptionsAction,
  applyReplacementReasonFiltersAction,
  fetchGridZonesOptionsAction,
  fetchVoltageLevelsOptionsAction,
  fetchSummaryPlanAction,
  fetchSummaryPlanSummaryGroupOptionsAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
