import { createAction } from 'redux-actions';
import { appLangSelector } from 'modules/app/selectors';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, plumberAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchFusesAction: any = createAction(
  'network-loading/FETCH_FUSES',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<NetworkLoading.Root, 'fusesHash' | 'fusesCount'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.NETWORK_LOADING_FUSES
      )(state);
      return plumberAPI
        .get('load/load_fuses', {
          params: {
            limit: skipPagination ? 10000 : limit,
            offset: skipPagination ? 0 : offset,
            sort,
            column,
            query,
            lang: appLangSelector(state).toLowerCase(),
            voltage: filters?.voltage,
            hide_solved: filters?.solvedValue,
            year: filters?.year,
          },
        })
        .then((res: any) => ({
          fusesCount: res.data.count,
          fusesHash: _keyBy(res.data.rows, (item: NetworkLoading.Fuse) => item.kabel),
          skipStoreUpdate,
        }));
    }
);

export const fetchFusesForecastAction: any = createAction(
  'network-loading/FETCH_FUSES_FORECAST',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<NetworkLoading.Root, 'fusesForecastsHash' | 'fusesForecastsCount'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.NETWORK_LOADING_FUSES_FORECAST
      )(state);
      return plumberAPI
        .get('/load_forecasting/fuses', {
          params: {
            limit: skipPagination ? 10000 : limit,
            offset: skipPagination ? 0 : offset,
            sort,
            column,
            query,
            lang: appLangSelector(state).toLowerCase(),
            hide_solved: filters?.solvedValue,
            voltage: filters?.voltage,
            year: filters?.year,
          },
        })
        .then((res: any) => ({
          fusesForecastsCount: res.data.count,
          fusesForecastsHash: _keyBy(res.data.rows, (item: NetworkLoading.FuseForecast) => item.id),
          skipStoreUpdate,
        }));
    }
);
