import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { fetchMapInvestmentScenariosAction, fetchConfigAction } from 'modules/map';
import { isMapConfigFetchedSelector } from 'modules/map/selectors';
import MapContainer from 'components/Map/core/MapContainer';
import { ErrorBoundary, MapNotifications, Spinner } from 'components/_common';
import { mapSimulationOptionSelector } from 'modules/options/selectors';

const Map: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const mapSimulationOption = useSelector(mapSimulationOptionSelector);
  const isMapConfigFetched = useSelector(isMapConfigFetchedSelector);

  useEffect(() => {
    if (!portfolioId || !mapSimulationOption) return;
    dispatch(
      fetchConfigAction({
        portfolioId,
        versionId: mapSimulationOption?.versionId,
        simulationId: Number(mapSimulationOption?.value),
      })
    );
  }, [dispatch, portfolioId, mapSimulationOption]);

  useEffect(() => {
    if (!scenarioId) return;
    dispatch(fetchMapInvestmentScenariosAction(scenarioId));
  }, [dispatch, scenarioId]);

  return (
    <StyledSection>
      <ErrorBoundary>
        <MapNotifications />
        {!isMapConfigFetched ? <Spinner isInFullHeightContainer /> : <MapContainer />}
      </ErrorBoundary>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  position: relative;
  height: calc(100vh - ${props => props.theme.heights.topNavigation});
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Map;
