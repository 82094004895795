import * as d3 from 'd3';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { push } from 'connected-react-router';
import { mapPanelSelectedAssetUUIDSelector } from 'modules/router/selectors';
import DiagramDefs from './DiagramDefs';
import { useResizeObserver, setupGraph, setupScales, drawGraph, removeHighlight, transformChart } from './utils';
import { isProduction, Routes, SingleLineDiagramTypes } from 'constants/index';
import { DefaultCircleRadius, ScaleExponentValue } from './constants';

interface Props {
  data: SingleLineDiagram.ResponseData;
  type: SingleLineDiagram.Types;
}

const Diagram: React.FC<Props> = ({ data, type }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const [diagramRendered, setDiagramRendered] = useState(false);

  const svgRef = useRef<SVGSVGElement | null>(null);
  const dimensions = useResizeObserver(svgRef);
  const maxZoom = 12;
  const zoom = useMemo(() => d3.zoom<any, unknown>().scaleExtent([0.9, maxZoom]), []);
  const uuid = useSelector(mapPanelSelectedAssetUUIDSelector);
  const history = useHistory<Pick<DataQuality.Issue, 'array_asset_uuids'>>();

  const zoomToFitSelectedElements = useCallback(
    (
      selectedCircles: d3.Selection<SVGGraphicsElement, unknown, null, any>,
      selectedLinks: d3.Selection<SVGElement, SingleLineDiagram.Edge, HTMLElement, any>
    ) => {
      const svg = d3.select(svgRef.current);
      const [width, height] = [Number(svg.attr('width')), Number(svg.attr('height'))];
      const padding = 25;

      let minX = Infinity,
        minY = Infinity,
        maxX = -Infinity,
        maxY = -Infinity;

      // Calculate bounding box for circles
      selectedCircles.each(function () {
        const circle = d3.select(this);
        const x = Number(circle.attr('cx'));
        const y = Number(circle.attr('cy'));

        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
      });

      // Calculate bounding box for links
      selectedLinks.each(function (d: SingleLineDiagram.Edge) {
        const sourceX = d.source!.V1;
        const sourceY = height - d.source!.V2;
        const targetX = d.target!.V1;
        const targetY = height - d.target!.V2;

        minX = Math.min(minX, sourceX, targetX);
        minY = Math.min(minY, sourceY, targetY);
        maxX = Math.max(maxX, sourceX, targetX);
        maxY = Math.max(maxY, sourceY, targetY);
      });

      if (minX === Infinity || minY === Infinity || maxX === -Infinity || maxY === -Infinity) return;

      // Calculate the center of the bounding box
      const x = (minX + maxX) / 2;
      const y = (minY + maxY) / 2;

      // Calculate zoom level
      const bboxWidth = maxX - minX;
      const bboxHeight = maxY - minY;
      const totalWidth = bboxWidth + padding * 2;
      const totalHeight = bboxHeight + padding * 2;
      const zoomLevel = Math.min(maxZoom, width / totalWidth, height / totalHeight);

      const transform = d3.zoomIdentity
        .translate(width / 2 - x * zoomLevel, height / 2 - y * zoomLevel)
        .scale(zoomLevel);
      svg.transition().duration(1000).call(zoom.transform, transform);
    },
    [zoom.transform]
  );

  useEffect(() => {
    const uuids = history.location.state?.array_asset_uuids || (uuid ? [uuid] : []);
    if (!isProduction) console.log('uuids', uuids);
    if (!diagramRendered || !uuids?.length) return;
    removeHighlight();

    const selectedCircles = d3
      .selectAll<SVGCircleElement, SingleLineDiagram.Vertice>('.circle')
      .filter((d: SingleLineDiagram.Vertice) => uuids.includes(d.customer_asset_id))
      .nodes()
      .map((circle: SVGCircleElement) => {
        const parentNode = circle.parentNode as SVGGraphicsElement;
        d3.select(parentNode).classed('selected-node', true);
        return parentNode;
      });
    // Convert the array of parent nodes back to a D3 selection
    const selectedParents = d3.selectAll(selectedCircles);

    // Select and filter links
    const selectedLinks = d3
      .selectAll<SVGElement, SingleLineDiagram.Edge>('.link')
      .filter(
        (d: SingleLineDiagram.Edge) =>
          uuids.includes(d.customer_asset_id) && !!d.source && !!d.target && type === SingleLineDiagramTypes.Tree
      )
      .each(function (this: SVGElement, d: SingleLineDiagram.Edge) {
        d3.select(this).classed('selected-link', true);
      });

    if (!isProduction) console.log('Selected elements', selectedParents, selectedLinks);
    if (selectedParents.empty() && selectedLinks.empty()) return;
    zoomToFitSelectedElements(selectedParents, selectedLinks);
  }, [diagramRendered, uuid, history.location.state?.array_asset_uuids, zoomToFitSelectedElements, type]);

  const handleGraphClick = useCallback(
    (uuid: string) => {
      if (!uuid) return dispatch(push(generatePath(Routes.Map)));
      dispatch(push(generatePath(Routes.Map, { uuid }), { keepMapState: true }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!svgRef.current || !dimensions) return;
    setDiagramRendered(false);

    // Set the dimensions and margins of the graph
    const { width, height } = dimensions;
    const delta = 3.5;

    // Append the svg object to the chart div
    const svg = d3.select(svgRef.current).attr('width', width).attr('height', height);

    // Append groups for nodes and links
    const linksGroup = svg.select('.links-group');
    const nodesGroup = svg.select('.nodes-group');

    // Prepare graph data
    const graph = setupGraph({ data, type, width, height });

    // Prepare scales
    const { xScale, yScale } = setupScales({ vertices: data.vertices, width, height, invert: false });
    let currentXScale = xScale;
    let currentYScale = yScale;

    // Draw the nodes, links and markers
    const { nodes, nodeCircles, nodeLabels, links } = drawGraph(
      graph,
      linksGroup,
      nodesGroup,
      delta,
      xScale,
      yScale,
      handleGraphClick
    );

    let scale = 1;
    const getPower = () => Math.pow(scale, ScaleExponentValue);

    // Dragging
    nodes.call(
      d3.drag().on('drag', function (this: any, event: any, d: any) {
        d.V1 += event.dx / scale;
        d.V2 -= event.dy / scale;
        transformChart(d3.select(this), links, currentXScale, currentYScale, delta * getPower());
      })
    );

    const nodeLabelsLowVoltage = nodeLabels.filter(function (this: SVGElement) {
      return d3.select(this).classed('voltage-low');
    });

    // Configure zoom
    const zoomed = (event: any) => {
      // update scales
      currentXScale = event.transform.rescaleX(xScale);
      currentYScale = event.transform.rescaleY(yScale);

      // update node positions
      scale = event.transform.k;
      const power = getPower();
      transformChart(nodes, links, currentXScale, currentYScale, delta * power);
      links.style('stroke-width', power);
      nodeCircles.attr('r', DefaultCircleRadius * power);
      nodeLabels.attr('transform', `scale(${power})`);
      nodeLabelsLowVoltage.style('opacity', Number(scale > 2));
    };
    zoom.on('zoom', zoomed);
    svg.call(zoom);
    setDiagramRendered(true);
  }, [data, type, dimensions, zoom, handleGraphClick]);

  return (
    <div className="single-line-chart" style={{ height: '100%', padding: '40px 50px' }}>
      <svg ref={svgRef} style={{ width: '100%', height: '100%', overflow: 'visible' }}>
        <DiagramDefs />
        <g className="links-group" />
        <g className="nodes-group" />
      </svg>
    </div>
  );
};

export default Diagram;
