import styled from 'styled-components';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { releaseNotificationModalVersionSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { Modal } from 'components/_common';
import { currentAppReleaseVersionSelector } from 'modules/app/selectors';

const ModalReleaseNotification: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [show, setShow] = useState(false);
  const releaseNotificationModalVersion = useSelector(releaseNotificationModalVersionSelector);
  const currentAppReleaseVersion = useSelector(currentAppReleaseVersionSelector);
  const isShow = currentAppReleaseVersion > Number(releaseNotificationModalVersion);

  useEffect(() => {
    if (isShow) setShow(true);
  }, [isShow]);

  const handleCloseBtnClick = useCallback(() => {
    setShow(false);
    setTimeout(() => dispatch(setLayoutAction({ releaseNotificationModalVersion: currentAppReleaseVersion })), 500);
  }, [dispatch, currentAppReleaseVersion]);

  return (
    <Modal
      show={show}
      onHide={handleCloseBtnClick}
      title={
        <span>
          &#127881; {getIntl("What's new in version {{version}}", { version: process.env.REACT_APP_VERSION! })}
        </span>
      }
      cancelButtonProps={{ labelKey: 'Close', onClick: handleCloseBtnClick }}
    >
      <>
        <p>{getIntl('Discover the latest enhancements and features in our web app')}:</p>
        {/* <ReleaseNotesList>
          <figcaption>&#128640; {getIntl('Features')}:</figcaption>
          <figcaption style={{ marginTop: '10px', marginLeft: '10px' }}>- Global:</figcaption>
          <ul>
            <li>"Multi select filters" &gt; new design and hover effect.</li>
            <li>"App setting" &gt; Simulations filter depend on selected scenario.</li>
          </ul>
          <figcaption style={{ marginTop: '10px', marginLeft: '10px' }}>- Map:</figcaption>
          <ul>
            <li>"Tasks" &gt; details popup for convenience.</li>
          </ul>
        </ReleaseNotesList> */}
        <ReleaseNotesList>
          <figcaption>&#128736; {getIntl('Improvements')}:</figcaption>
          <figcaption style={{ marginTop: '10px', marginLeft: '10px' }}>- Map:</figcaption>
          <ul>
            <li>Unused fly to layer functionality removed.</li>
            <li>Get config, migrated to Python endpoint.</li>
            <li>Get data quality warning, migrated to Python endpoint.</li>
            <li>Get investment scenarios, migrated to Python endpoint.</li>
          </ul>
          <figcaption style={{ marginTop: '10px', marginLeft: '10px' }}>- Admin:</figcaption>
          <ul>
            <li>Table and popup permissions grouping for better user experience.</li>
          </ul>
          <figcaption style={{ marginTop: '10px', marginLeft: '10px' }}>- General:</figcaption>
          <ul>
            <li>Table buttons color consolidated for design consistency.</li>
            <li>User menu, "Admin" and "Logout" buttons smaller size.</li>
          </ul>
        </ReleaseNotesList>

        <ReleaseNotesList>
          <figcaption>&#128030; {getIntl('Fixes')}:</figcaption>
          <ul>
            <li>"Data quality &gt; Issues table": show on map link, load map properly.</li>
            <li>"N-1 table": show on map link, load map properly.</li>
            <li>"Setup &gt; Investment": Component price scenarios tab missing translation.</li>
          </ul>
        </ReleaseNotesList>

        <p>{getIntl('Thank You for Your Collaboration')}! &#128578;</p>
        <p>
          {getIntl(
            'We appreciate your continued feedback, which help us improve and grow. Stay tuned for more exciting updates and features. Together, we’re making great things happen'
          )}
          !
        </p>
      </>
    </Modal>
  );
};

const ReleaseNotesList = styled.figure`
  margin-left: 20px;
  > ul {
    line-height: 2;
    margin-bottom: 0;
  }
`;

export default ModalReleaseNotification;
